import type { Splide } from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const onSlideMouseEnter = () => {
  document.body.classList.add('cursor-grab');
};

const onSlideMouseLeave = () => {
  document.body.classList.remove('cursor-grab');
};

const onSlideMouseDown = () => {
  document.body.classList.add('!cursor-grabbing');
};

const onSlideMouseUp = () => {
  document.body.classList.remove('!cursor-grabbing');
};

export default defineModule(
  async () => {
    const quoteSliderContentBlockElements =
      document.querySelectorAll<HTMLElement>(
        '.content-block.content-block--quote-slider',
      );
    if (!quoteSliderContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    quoteSliderContentBlockElements.forEach(
      (quoteSliderContentBlockElement) => {
        const splideElement =
          quoteSliderContentBlockElement.querySelector<HTMLElement>('.splide');
        if (!splideElement) return;

        const hasMultipleSlides =
          splideElement.querySelectorAll('.splide__slide').length > 1;

        const splide = new Splide(splideElement, {
          type: 'loop',
          autoplay: true,
          interval: 7000,
          gap: '2.25rem',
          arrows: false,
          drag: hasMultipleSlides,
        }).mount();

        splide.Components.Slides.forEach(({ slide }) => {
          slide.addEventListener('mousedown', onSlideMouseDown);
          slide.addEventListener('mouseup', onSlideMouseUp);
          slide.addEventListener('mouseenter', onSlideMouseEnter);
          slide.addEventListener('mouseleave', onSlideMouseLeave);
        });
        splide.on('destroy', () => {
          splide.Components.Slides.forEach(({ slide }) => {
            slide.removeEventListener('mousedown', onSlideMouseDown);
            slide.removeEventListener('mouseup', onSlideMouseUp);
            slide.removeEventListener('mouseenter', onSlideMouseEnter);
            slide.removeEventListener('mouseleave', onSlideMouseLeave);
          });
        });

        splide.on('dragged', () => {
          document.body.classList.remove('!cursor-grabbing');
        });

        splides.push(splide);
      },
    );
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
